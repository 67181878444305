<template>
  <lazy-load #default="{ visible }">
    <template v-if="!visible">
      <grid-banner-skeleton/>
    </template>
    <template v-else>
      <device-wrapper #default="{ desktop }">
        <div :style="`background: #${data?.background_color};`">
          <div v-if="data?.name" :class="data?.background_color ? 'py-3' : 'pb-3'"
               :style="`color: #${data?.text_color};`" class="font-niceone-medium text-lg px-3">
            {{ data?.name }}
          </div>
          <div v-if="!desktop" class="flex overflow-x-auto pb-3 ps-3 width-full">
            <video-item v-for="(item, index) in data.data" :key="`video-item-${index}`" :item="item"
                        @click="openVideoDailog(item)"/>
          </div>
        </div>
      </device-wrapper>
    </template>
  </lazy-load>
</template>

<script setup>
import LazyLoad from "@/components/LazyLoad"
import GridBannerSkeleton from "@/components/skeleton/GridBanner"
import VideoItem from "@/components/dynamic_components/video-slider/VideoItem"
import {EVENTS} from "@/constants/events";
import {useEventBus} from "@vueuse/core/index";

const props = defineProps({
  data: [Object],
  loading: {
    type: Boolean,
    default: false
  }
})

const {emit: openVideoDailog} = useEventBus(EVENTS.VIDEO_DIALOG);
</script>