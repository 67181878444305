<template>
  <div
      :style="{ backgroundImage: `url(${item.home_videos?.thumbnail_url})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }"
      class="relative h-[197px] min-w-[135px] rounded-xl ltr:mr-3 rtl:ml-3 cursor-pointer"
  >
    <div class="absolute inset-0 bg-black opacity-30 rounded-xl z-0"></div>
    <niceone-image :src="$publicPath('/images/video/play.svg')" class="m-2 relative z-1" width="18"/>

    <div class="absolute left-0 right-0 bottom-3 z-1">
      <div class="flex flex-col px-3">
        <van-text-ellipsis v-if="item?.home_videos?.title" :content="item?.home_videos?.title"
                           class="text-white text-xs mb-1 font-bold"/>
        <div class="flex items-center">
          <div v-if="getImageUrl"
               class="bg-white rounded-full overflow-hidden">
            <div
                :style="{ backgroundImage: `url(${getImageUrl})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }"
                class="w-[20px] h-[20px] rounded-full"></div>
          </div>
          <div class="ms-2 text-white text-xs van-multi-ellipsis--l2">{{ item.home_videos?.mf_name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: () => {
    }
  }
})
const getImageUrl = computed(() => {
  return props.item?.home_videos?.mf_image_url.replaceAll(' ', '%20')
})
</script>

<style scoped>

</style>